<template>
  <v-select v-model="model" @input="onInput" :options="heatingOptions" :clearable="false" :reduce="t => t.id" v-if="edit" />
  <span v-else class="heating-type-picker">{{ selectedLabel }}</span>
</template>
<script>

export default {
  props: {
    value: {
      type: String
    },
    exclude: {
      type: Array
    },
    edit: {
      type: Boolean
    }
  },
  data () {
    return {
      model: null
    }
  },
  watch: {
    value () {
      this.model = this.value
    }
  },
  created: function () {
    this.model = this.value
  },
  computed: {
    heatingOptions () {
      let options = [
        { id: 'UNKNOWN', label: this.$t('_heatingTypes.UNKNOWN') },
        { id: 'OIL', label: this.$t('_heatingTypes.OIL') },
        { id: 'GAS', label: this.$t('_heatingTypes.GAS') },
        { id: 'DISTRICT', label: this.$t('_heatingTypes.DISTRICT') },
        { id: 'LOCAL', label: this.$t('_heatingTypes.LOCAL') },
        { id: 'PELLET', label: this.$t('_heatingTypes.PELLET') },
        { id: 'WOOD_CHIPS', label: this.$t('_heatingTypes.WOOD_CHIPS') },
        { id: 'WOOD', label: this.$t('_heatingTypes.WOOD') },
        { id: 'HP_AIR', label: this.$t('_heatingTypes.HP_AIR') },
        { id: 'HP_GW', label: this.$t('_heatingTypes.HP_GW') },
        { id: 'HP_EWS', label: this.$t('_heatingTypes.HP_EWS') },
        { id: 'SOLAR', label: this.$t('_heatingTypes.SOLAR') },
        { id: 'ELECTRIC', label: this.$t('_heatingTypes.ELECTRIC') }
      ]
      return options.filter(o => !this.exclude || !this.exclude.includes(o.id))
    },
    selectedLabel () {
      return this.heatingOptions.find(o => o.id === this.model).label
    }
  },
  methods: {
    onInput () {
      this.$emit('input', this.model)
    }
  }
}
</script>
<style>
.heating-type-picker {
  margin-right: var(--spacing-xs);
}
</style>
