<template>
  <div class="two-column-row">
    <div class="form-label">
      {{ label }}
      <info-box v-if="infoText" :title="infoTitle ? infoTitle : label">{{ infoText }}</info-box>
    </div>
    <div><slot /></div>
  </div>
</template>
<script>
import InfoBox from '@/components/shared/InfoBox'

export default {
  props: {
    label: {
      type: String
    },
    infoTitle: {
      type: String
    },
    infoText: {
      type: String
    }
  },
  components: {
    InfoBox
  }
}
</script>
<style scoped>
.form-label {
  font-weight: 500;
  color: var(--secondary-highlight-color);
}
.two-column-row {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: baseline;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
</style>
