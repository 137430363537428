<template>
  <span class="info-box">
    <v-popover trigger="hover" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
      <template slot="popover">
        <header v-if="title">{{ title }}</header>
        <main><slot /></main>
      </template>
    </v-popover>
  </span>
</template>

<script>
import { VPopover } from 'v-tooltip'

export default {
  props: {
    title: {
      type: String
    }
  },
  components: {
    VPopover
  }
}
</script>
<style scoped>
  .feather-alert-circle {
    color: var(--secondary-highlight-color);
    height: 20px;
    vertical-align: middle;
  }
  .feather-alert-circle.edit:hover {
    height: 24px;
  }
  .v-popover {
    display: inline-block;
  }
</style>
