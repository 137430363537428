<i18n>
{
  "de": {
    "emptyErrorMessage": "Wert darf nicht leer sein",
    "notANumberErrorMessage": "{text} ist keine Zahl",
    "notAnIntegerErrorMessage": "Wert muss eine Ganzzahl sein",
    "tooSmallErrorMessage": "Wert darf nicht kleiner als {min} sein",
    "tooLargeErrorMessage": "Wert darf nicht grösser als {max} sein"
  }
}
</i18n>

<template>
  <span class="numeric-input" :class="classes">
    <template v-if="edit">
      <input ref="input" type="text" :value="value" @input="updateValue($event.target.value)" @change="updateValue($event.target.value)" @focus="focus">
      <div class="units"><span v-if="units">{{ units }}</span></div>
      <span v-if="validationError" class="validation-error">{{ validationError}}</span>
    </template>
    <span v-else-if="value !== null && value !== undefined" class="read-only-value">
      <span v-if="noFormatting">{{ value }}</span>
      <span v-else>{{ formatNumber(value) }}</span> <span v-if="units">{{ units }}</span>
    </span>
    <span v-else class="read-only-value">{{ emptyValue }}</span>
  </span>
</template>

<script>

export default {
  props: {
    value: {
    },
    int: {
      type: Boolean,
      default: false
    },
    optional: {
      type: Boolean,
      default: false
    },
    noFormatting: {
      type: Boolean,
      default: false
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    units: {
      type: String
    },
    emptyValue: {
      type: String,
      default: '-'
    },
    invalidValues: {
      type: Array
    },
    invalidValuesErrorMessage: {
      type: String
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    validationError () {
      return this.getValidationError(this.value)
    },
    classes () {
      return {
        invalid: Boolean(this.validationError)
      }
    }
  },
  methods: {
    getValidationError (value) {
      if (value === undefined || value === null || value === '') {
        if (!this.optional) {
          return this.$t('emptyErrorMessage')
        }
      } else if (Number.isNaN(Number.parseFloat(value))) {
        return this.$t('notANumberErrorMessage', { text: value })
      } else if (this.int && Number.isNaN(Number.parseInt(value))) {
        return this.$t('notAnIntegerErrorMessage')
      } else if (this.min !== undefined && value < this.min) {
        return this.$t('tooSmallErrorMessage', { min: this.min })
      } else if (this.min !== undefined && value > this.max) {
        return this.$t('tooLargeErrorMessage', { max: this.max })
      } else if (this.invalidValues && this.invalidValues.includes(value)) {
        return this.invalidValuesErrorMessage
      }
      return null
    },
    updateValue (value) {
      // Convert string to number
      if (!Number.isNaN(Number.parseFloat(value))) {
        value = Number.parseFloat(value)
      }
      if (value === undefined || value === null || value === '') {
        value = null
      }
      this.$emit('input', value)
      this.$emit('validation', !this.getValidationError(value))
    },
    focus () {
      this.$refs.input.select()
    }
  }
}
</script>

<style>
.numeric-input {
  --box-padding-h: var(--spacing-xs);
  --box-padding-v: var(--spacing-xs);
  --box-padding: var(--box-padding-v) var(--box-padding-h);
  display: grid;
  grid-template-columns: 1fr max-content;
  white-space: nowrap;
}

.numeric-input > input {
  border-color: var(--hairline-color);
  width: 100%;
  overflow: visible;
  text-overflow: initial;
}

.numeric-input > input:focus {
  border-color: var(--secondary-highlight-color);
}

.numeric-input > input {
  text-align: end;
}

.numeric-input.invalid > input,
.numeric-input.invalid > .validation-error {
  color: var(--error-color);
}

.numeric-input.invalid > input {
  border: var(--box-error-border);
}

.numeric-input.invalid > .validation-error {
  display: block;
  font-weight: 400;
  font-size: var(--font-xs);
}
.numeric-input .units {
  margin: auto;
  margin-left: var(--spacing-xs);
}
</style>
