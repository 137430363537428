<i18n>
{
  "de": {
    "emptyErrorMessage": "Feld darf nicht leer sein",
    "tooShortErrorMessage": "Kürzer als {minLength}",
    "tooLongErrorMessage": "Länger als {maxLength}"
  }
}
</i18n>

<template>
  <div class="text-input" :class="classes">
    <template v-if="edit">
      <input :id="inputId" ref="input" :type="inputType" :value="value" @input="updateValue($event.target.value)" @change="updateValue($event.target.value)" @focus="focus">
      <label :for="inputId"><slot /></label>
      <span v-if="validationError" class="validation-error">{{ validationError }}</span>
    </template>
    <div v-else class="read-only-value"><span>{{ value }}</span><slot /></div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    value: {
      type: String
    },
    password: {
      type: Boolean,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    minLength: {
      type: Number
    },
    maxLength: {
      type: Number
    },
    invalidValues: {
      type: Array
    },
    invalidValuesErrorMessage: {
      type: String
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputId: uuidv4()
    }
  },
  computed: {
    validationError () {
      if (this.minLength !== undefined && this.value.length < this.minLength) {
        if (this.value.length) {
          return this.$t('tooShortErrorMessage', { minLength: this.minLength })
        } else {
          return this.$t('emptyErrorMessage')
        }
      } else if (this.maxLength !== undefined && this.value.length > this.maxLength) {
        return this.$t('tooLongErrorMessage', { maxLength: this.maxLength })
      } else if (this.invalidValues && this.invalidValues.includes(this.value)) {
        return this.invalidValuesErrorMessage
      }
      return null
    },
    inputType () {
      return this.password ? 'password' : 'text'
    },
    classes () {
      return {
        invalid: Boolean(this.validationError)
      }
    }
  },
  methods: {
    getValidationError (value) {
      if (this.minLength !== undefined && value.length < this.minLength) {
        if (value.length) {
          return this.$t('tooShortErrorMessage', { minLength: this.minLength })
        } else {
          return this.$t('emptyErrorMessage')
        }
      } else if (this.maxLength !== undefined && value.length > this.maxLength) {
        return this.$t('tooLongErrorMessage', { maxLength: this.maxLength })
      } else if (this.invalidValues && this.invalidValues.includes(value)) {
        return this.invalidValuesErrorMessage
      }
      return null
    },
    updateValue (value) {
      this.$emit('input', value)
      this.$emit('validation', !this.getValidationError(value))
    },
    focus () {
      this.$refs.input.select()
    }
  }
}
</script>

<style>
.text-input .read-only-value {
  /* padding: var(--box-padding); */
  border: 1px solid transparent;
}

.text-input > input {
  border-color: var(--hairline-color);
  width: 100%;
  overflow: visible;
  text-overflow: initial;
}

.text-input > input:focus {
  border-color: var(--secondary-highlight-color);
}

.text-input.invalid > input,
.text-input.invalid > .validation-error {
  color: var(--error-color);
}

.text-input.invalid > input {
  border: var(--box-error-border);
}

.text-input.invalid > .validation-error {
  display: block;
  font-weight: 400;
  font-size: var(--font-xs);
}

</style>
